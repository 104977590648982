import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
  Collapse,
} from "reactstrap"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import bgProfile from "assets/images/ugifts/profile_bg.png"
import bgFriend from "assets/images/ugifts/friend_bg.png"
import inviteFriend from "assets/images/ugifts/invite_friend.png"
import iconPoint from "assets/images/ugifts/icons/profile_point.png"
import iconRibbon from "assets/images/ugifts/icon_ribbon.png"
import inviteFriendBg from "assets/images/ugifts/invite_friend_purple_bg.png"

import { Button } from "@material-ui/core"

import emptyLogin from "assets/images/ugifts/icons/empty/empty_login.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const languageData = [
  { name: "English", short: "eng" },
  { name: "Chinese", short: "cn" },
  { name: "Japanese", short: "jp" },
]

const DanawaFAQMobile = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [basketCount, setBasketCount] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }

      if (query.get("lang")) {
        setlanguageSelected(query.get("lang"))
      } else {
        setlanguageSelected("eng")
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (uid != null) {
      // if (!isGuest) {
      getUserProfile()
      //   getFriendList()
      //   getOrderList()
      //   getBasketData()
      //   getUdollar()
      // }
      console.log("use effect")
    }
  }, [uid])

  const [userData, setUserData] = useState(null)
  const getUserProfile = async () => {
    // var form = JSON.stringify({
    //   option: "detail_profile",
    //   id: uid,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_profile")
    urlencoded.append("id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   // "Content-Type": "application/json",
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("Request user profile", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setUserData(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  const getOrderList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_order")
    urlencoded.append("member_id", uid)
    urlencoded.append("filter_by", "purchased")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request order list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_ORDER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  const [friendData, setFriendData] = useState(null)
  const getFriendList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_friend")
    urlencoded.append("order_by", "asc")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request category", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setFriendData(postresponse)
    } else {
      // seterror_alert(true)
    }
  }

  const getBasketData = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_basket")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      var calculate = 0
      postresponse.table.products.forEach(product => {
        calculate += parseInt(product.quantity)
      })
      localStorage.setItem("basketCount", calculate)
      setBasketCount(calculate)
    } else {
    }
  }

  const getUdollar = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_udollar_giftcards")
    urlencoded.append("member_id", 52)
    urlencoded.append("currency_code", "MYR")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request udollar list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  function logout() {
    localStorage.removeItem("authUser")
    // localStorage.removeItem("basketCount")
    // localStorage.removeItem("fbToken")
    history.push("/login")
  }

  const [languageSelected, setlanguageSelected] = useState(false)
  const [modal_language, setmodal_language] = useState(false)
  function tog_language() {
    setmodal_language(!modal_language)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [collapses, setCollapses] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  function toggleCollapse(id) {
    setCollapses(collapses => {
      return collapses.map((collapse, index) => {
        if (id === index) {
          return !collapse
        }
        return false
      })
    })
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | FAQ</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              // lg={10}
              // className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                // className="auth-full-page-content"
                // className="flex"
                style={{
                  display: "flex",
                  minHeight: "100vh",
                  // paddingTop: "56px",
                  paddingBottom: "105px",
                  // background: "#EBEBEB",
                }}
              >
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    lineHeight: 1.8,
                  }}
                >
                  <div className="d-flex flex-column h-100 p-3">
                    <Row className="justify-content-center">
                      {languageSelected == "eng" && (
                        <Col xs="12" className="accordion">
                          <Row>
                            <Col>
                              <span className="font-size-30 font-weight-semibold">
                                {"SRON FAQ"}
                              </span>
                            </Col>
                          </Row>
                          <hr />
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(0)}
                              >
                                {"1. How do I check SRON coverage in my area?"}
                              </button>
                              <Collapse
                                isOpen={collapses[0]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    To check SRON coverage, open the app and go
                                    to the
                                    <strong> Free Wi-Fi section</strong>. Click
                                    on the
                                    <strong> Check Coverage</strong> button,
                                    enter your location, and the app will
                                    display whether SRON's coverage is available
                                    in your area.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(1)}
                              >
                                {
                                  "2. What are the different types of packages available in SRON?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[1]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    <strong>Free Wi-Fi:</strong> Offers
                                    complimentary internet access in specific
                                    coverage areas.
                                  </li>
                                  <li>
                                    <strong>Public Connect:</strong> A paid
                                    voucher offering 1-month internet access
                                    (RM39).
                                  </li>
                                  <li>
                                    <strong>Home Connect:</strong> A service for
                                    home internet, where users can register by
                                    filling out an order form for installation.
                                  </li>
                                  <li>
                                    <strong>Corporate Connect:</strong> A
                                    service for businesses, where users can
                                    register by filling out a form with company
                                    details, followed by a site survey.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(2)}
                              >
                                {"3. How do I register for Home Connect?"}
                              </button>
                              <Collapse
                                isOpen={collapses[2]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    To register for{" "}
                                    <strong>Home Connect</strong>, go to the{" "}
                                    <strong>Home Connect</strong> section in the
                                    app and click on{" "}
                                    <strong>Register Now</strong>. Fill out the{" "}
                                    <strong>Order Form</strong> with your full
                                    name, IC number, address, and contact
                                    information. The form will be processed
                                    through the CRM system for installation.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(3)}
                              >
                                {
                                  "4. Can I use the same voucher for multiple devices?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[3]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    <strong>1 Voucher = 1 Device:</strong>{" "}
                                    <br />
                                    The voucher purchased through{" "}
                                    <strong>Public Connect</strong> is valid for
                                    only
                                    <strong> one device</strong>. Each voucher
                                    can be used on a single device at a time. If
                                    you need to connect additional devices, you
                                    will need to purchase separate vouchers for
                                    each device.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(4)}
                              >
                                {
                                  "5. What should I do if I have trouble connecting to SRON Wi-Fi?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[4]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    If you are having trouble connecting to SRON
                                    Wi-Fi:
                                    <ol>
                                      <li>
                                        Ensure you are in a covered area (use
                                        the
                                        <strong> Check Coverage</strong>{" "}
                                        button).
                                      </li>
                                      <li>
                                        Make sure your device is connected to
                                        the correct SRON network.
                                      </li>
                                      <li>
                                        Restart your device or reconnect to the
                                        Wi-Fi.
                                      </li>
                                      <li>
                                        If the issue persists, contact SRON
                                        support through the{" "}
                                        <strong>Contact Us</strong> section in
                                        the app for further assistance.
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(5)}
                              >
                                {"6. How do I update my profile details?"}
                              </button>
                              <Collapse
                                isOpen={collapses[5]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    To update your profile details:
                                    <ol>
                                      <li>
                                        Go to the <strong>Profile</strong>{" "}
                                        section of the app.
                                      </li>
                                      <li>
                                        Click on <strong>Edit Profile</strong>{" "}
                                        to change personal details like your
                                        email, password, or contact information.
                                      </li>
                                      <li>
                                        Once updated, click{" "}
                                        <strong>Save</strong> to confirm the
                                        changes.
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(6)}
                              >
                                {
                                  "7. How can I purchase a voucher for Public Connect?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[6]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    To purchase a voucher for{" "}
                                    <strong>Public Connect</strong>, navigate to
                                    the
                                    <strong> Public Connect</strong> section,
                                    and click on the{" "}
                                    <strong>Purchase Voucher</strong> button.
                                    Select the payment option (FPX or Spay),
                                    complete the transaction, and the app will
                                    generate a Voucher Code for you.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(7)}
                              >
                                {
                                  "8. How long is the validity of a Public Connect voucher?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[7]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    A voucher for{" "}
                                    <strong>Public Connect</strong> is valid for{" "}
                                    <strong>1 month</strong> after activation.
                                    Once activated, you can use it for internet
                                    access within that timeframe.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(8)}
                              >
                                {
                                  "9. How do I contact SRON for support or inquiries?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[8]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    You can contact SRON support directly
                                    through the <strong>FAQ</strong> or{" "}
                                    <strong>Contact Us</strong> section in the
                                    app. For corporate inquiries, the{" "}
                                    <strong>Corporate Connect </strong>
                                    section allows you to register and get in
                                    touch with the Sales & Marketing team.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(9)}
                              >
                                {
                                  "10. What payment methods are available for purchasing vouchers?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[9]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    You can pay for{" "}
                                    <strong>Public Connect</strong> vouchers
                                    using <strong>FPX</strong> (Financial
                                    Process Exchange) or <strong>Spay</strong>{" "}
                                    (payment gateway). Choose your preferred
                                    payment method at checkout.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(10)}
                              >
                                {
                                  "11. Can I modify or cancel my Home Connect order?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[10]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Once a <strong>Home Connect</strong> order
                                    is submitted, it cannot be canceled or
                                    modified through the app. However, you can
                                    contact the SRON support team for assistance
                                    if needed.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(11)}
                              >
                                {"12. How do I change the language in the app?"}
                              </button>
                              <Collapse
                                isOpen={collapses[11]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    To change the language in the app, go to the
                                    top-right corner of the app, where you can
                                    select either <strong>English</strong> or{" "}
                                    <strong>Malay </strong>
                                    as your preferred language.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(12)}
                              >
                                {
                                  "13. Can I transfer my voucher to another user?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[12]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    No, vouchers purchased through SRON are
                                    non-transferable and are tied to the account
                                    used for the purchase.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {languageSelected == "bm" && (
                        <Col xs="12" className="accordion">
                          <Row>
                            <Col>
                              <span className="font-size-30 font-weight-semibold">
                                {"SRON FAQ"}
                              </span>
                            </Col>
                          </Row>
                          <hr />
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(0)}
                              >
                                {
                                  "1. Bagaimana cara untuk memeriksa liputan SRON di kawasan saya?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[0]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Untuk memeriksa liputan SRON, buka aplikasi
                                    dan pergi ke bahagian
                                    <strong> Wi-Fi Percuma</strong>. Klik pada
                                    butang
                                    <strong> Semak Liputan</strong>, masukkan
                                    lokasi anda, dan aplikasi akan memaparkan
                                    sama ada liputan SRON tersedia di kawasan
                                    anda.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(1)}
                              >
                                {
                                  "2. Apakah jenis pakej yang tersedia dalam SRON?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[1]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    <strong>Wi-Fi Percuma:</strong> Menawarkan
                                    akses internet percuma di kawasan liputan
                                    tertentu.
                                  </li>
                                  <li>
                                    <strong>Public Connect:</strong> Baucar
                                    berbayar yang menawarkan akses internet
                                    selama 1 bulan (RM39).
                                  </li>
                                  <li>
                                    <strong>Home Connect:</strong> Perkhidmatan
                                    internet untuk rumah, di mana pengguna boleh
                                    mendaftar dengan mengisi borang pesanan
                                    untuk pemasangan.
                                  </li>
                                  <li>
                                    <strong>Corporate Connect:</strong>{" "}
                                    Perkhidmatan untuk perniagaan, di mana
                                    pengguna boleh mendaftar dengan mengisi
                                    borang yang mengandungi butiran syarikat,
                                    diikuti dengan tinjauan tapak.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(2)}
                              >
                                {
                                  "3. Bagaimana cara saya untuk mendaftar untuk Home Connect?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[2]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Untuk mendaftar bagi{" "}
                                    <strong>Home Connect</strong>, pergi ke
                                    bahagian <strong>Home Connect</strong> dalam
                                    aplikasi dan klik pada{" "}
                                    <strong>Daftar Sekarang</strong>. Isikan{" "}
                                    <strong>Borang Pesanan</strong> dengan nama
                                    penuh, nombor IC, alamat, dan maklumat
                                    perhubungan anda. Borang tersebut akan
                                    diproses melalui sistem CRM untuk
                                    pemasangan.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(3)}
                              >
                                {
                                  "4. Bolehkah saya menggunakan baucar yang sama untuk beberapa peranti?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[3]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    <strong>1 Baucar = 1 Peranti:</strong>{" "}
                                    <br />
                                    Baucar yang dibeli melalui{" "}
                                    <strong>Public Connect</strong> hanya sah
                                    untuk
                                    <strong> satu peranti</strong> sahaja.
                                    Setiap baucar hanya boleh digunakan pada
                                    satu peranti pada satu masa. Jika anda perlu
                                    menyambung peranti tambahan, anda perlu
                                    membeli baucar berasingan untuk setiap
                                    peranti.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(4)}
                              >
                                {
                                  "5. Apa yang perlu saya lakukan jika saya menghadapi masalah untuk menyambung ke Wi-Fi SRON?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[4]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Jika anda menghadapi masalah untuk
                                    menyambung ke Wi-Fi SRON:
                                    <ol>
                                      <li>
                                        Pastikan anda berada di kawasan yang
                                        diliputi (gunakan butang
                                        <strong> Semak Liputan</strong>).
                                      </li>
                                      <li>
                                        Pastikan peranti anda disambungkan ke
                                        rangkaian SRON yang betul.
                                      </li>
                                      <li>
                                        Mulakan semula peranti anda atau
                                        sambungkan semula ke Wi-Fi.
                                      </li>
                                      <li>
                                        Jika masalah berterusan, hubungi
                                        sokongan SRON melalui bahagian{" "}
                                        <strong>Hubungi Kami</strong> dalam
                                        aplikasi untuk bantuan lanjut.
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(5)}
                              >
                                {
                                  "6. Bagaimana cara untuk mengemas kini butiran profil saya?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[5]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Untuk mengemas kini butiran profil anda:
                                    <ol>
                                      <li>
                                        Pergi ke bahagian{" "}
                                        <strong>Profil</strong> dalam aplikasi.
                                      </li>
                                      <li>
                                        Klik pada <strong>Edit Profil</strong>{" "}
                                        untuk menukar butiran peribadi seperti
                                        emel, kata laluan, atau maklumat
                                        perhubungan.
                                      </li>
                                      <li>
                                        Setelah dikemas kini, klik{" "}
                                        <strong>Simpan</strong> untuk
                                        mengesahkan perubahan.
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(6)}
                              >
                                {
                                  "7. Bagaimana cara untuk membeli baucar untuk Public Connect?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[6]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Untuk membeli baucar bagi{" "}
                                    <strong>Public Connect</strong>, pergi ke
                                    bahagian
                                    <strong> Public Connect</strong>, dan klik
                                    pada butang <strong>Beli Baucar</strong>.
                                    Pilih pilihan pembayaran (FPX atau Spay),
                                    lengkapkan transaksi, dan aplikasi akan
                                    menghasilkan Kod Baucar untuk anda.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(7)}
                              >
                                {
                                  "8. Berapa lama tempoh sah baucar Public Connect?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[7]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Baucar <strong>Public Connect</strong> sah
                                    selama <strong>1 bulan</strong> selepas
                                    diaktifkan. Setelah diaktifkan, anda boleh
                                    menggunakannya untuk akses internet dalam
                                    tempoh masa tersebut. Kami menggunakan kuki
                                    untuk meningkatkan pengalaman aplikasi anda.
                                    Anda boleh menguruskan tetapan kuki melalui
                                    tetapan pelayar anda.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(8)}
                              >
                                {
                                  "9. Bagaimana cara untuk menghubungi SRON untuk sokongan atau pertanyaan?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[8]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Anda boleh menghubungi sokongan SRON secara
                                    langsung melalui bahagian{" "}
                                    <strong>Soalan Lazim</strong> atau{" "}
                                    <strong>Hubungi Kami</strong> dalam
                                    aplikasi. Untuk pertanyaan korporat,
                                    bahagian Corporate Connect membolehkan anda
                                    mendaftar dan berhubung dengan pasukan
                                    Jualan & Pemasaran.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(9)}
                              >
                                {
                                  "10. Apakah kaedah pembayaran yang tersedia untuk membeli baucar?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[9]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Anda boleh membayar baucar{" "}
                                    <strong>Public Connect</strong> menggunakan{" "}
                                    <strong>FPX</strong> (Financial Process
                                    Exchange) atau <strong>Spay</strong>{" "}
                                    (gerbang pembayaran). Pilih kaedah
                                    pembayaran pilihan anda semasa pembayaran.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(10)}
                              >
                                {
                                  "11. Bolehkah saya mengubah atau membatalkan pesanan Home Connect saya?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[10]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Setelah pesanan{" "}
                                    <strong>Home Connect</strong> dihantar, ia
                                    tidak boleh dibatalkan atau diubah melalui
                                    aplikasi. Walau bagaimanapun, anda boleh
                                    menghubungi pasukan sokongan SRON untuk
                                    bantuan jika perlu.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(11)}
                              >
                                {
                                  "12. Bagaimana cara untuk menukar bahasa dalam aplikasi?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[11]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16">
                                  <li>
                                    Untuk menukar bahasa dalam aplikasi, pergi
                                    ke penjuru kanan atas aplikasi, di mana anda
                                    boleh memilih sama ada{" "}
                                    <strong>Bahasa Inggeris</strong> atau{" "}
                                    <strong>Bahasa Melayu </strong>
                                    sebagai bahasa pilihan anda.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="w-100 font-size-16 font-weight-semibold text-start bg-transparent btn btn-outline-light"
                                onClick={() => toggleCollapse(12)}
                              >
                                {
                                  "13. Bolehkah saya memindahkan baucar saya kepada pengguna lain?"
                                }
                              </button>
                              <Collapse
                                isOpen={collapses[12]}
                                id="accordion-collapse"
                              >
                                <ul className="font-size-16 accordion-body">
                                  <li>
                                    Tidak, baucar yang dibeli melalui SRON tidak
                                    boleh dipindahkan dan terikat dengan akaun
                                    yang digunakan untuk pembelian.
                                  </li>
                                </ul>
                              </Collapse>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaFAQMobile)

DanawaFAQMobile.propTypes = {
  t: PropTypes.any,
}
